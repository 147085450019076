import React from "react"
import { graphql } from "gatsby"
export default ( props ) => {
  return
}

export const ProcedureQuery = graphql`
fragment ProcedureQuery on ProceduresJson {
  name
  blurb
  procedureLevel
  title
  order
  thumbnailPublicId
}
`
export const SpanishProcedureQuery = graphql`
fragment SpanishProcedureQuery on SpanishProceduresJson {
  name
  blurb
  procedureLevel
  title
  order
  thumbnailPublicId
  generatePage
}
`